import React, { useEffect, useState } from "react";
import "./App.scss";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/screens/home";
import Calendar from "./components/screens/calendar";
import PreviousPours from "./components/screens/previousPours";
import Error404 from "./components/screens/error404";
import Login from "./components/screens/login";
import Bylaws from "./components/screens/bylaws";
import Members from "./components/screens/members";
import Draft from './components/screens/draft';

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { CircularProgress } from "@mui/material";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDw7FpBkkoqI2RkazuaDAH87RmyF7BIPyw",
  authDomain: "whiskey-club-37f90.firebaseapp.com",
  projectId: "whiskey-club-37f90",
  storageBucket: "whiskey-club-37f90.appspot.com",
  messagingSenderId: "325157876448",
  appId: "1:325157876448:web:40e24e8da05ce613689685",
};
// Initialize Firebase
const app: any = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error404 />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
    errorElement: <Error404 />,
  },
  {
    path: "/previous-pours",
    element: <PreviousPours />,
    errorElement: <Error404 />,
  },
  {
    path: "/by-laws",
    element: <Bylaws />,
    errorElement: <Error404 />,
  }, 
  {
    path: "/members",
    element: <Members />,
    errorElement: <Error404 />,
  }, 
  {
    path: "/draft",
    element: <Draft />,
    errorElement: <Error404 />,
  }, 
]);

export default function App() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loggedin, setLoggedin] = useState(false);
  const [loading, setLoading] = useState(true);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setLoggedin(true);
      // ...
    } else {
      // User is signed out
      // ...
      setLoggedin(false);
    }
    setLoading(false);
  });

  const loginUser = (data: any) => {
    setLoggedin(data);
  };

  if(loading){
    return(
      <CircularProgress />
    )
  }
  return (
    <div>
      {loggedin ? (
        <RouterProvider router={router} />
      ) : (
        <Login onLogin={loginUser} />
      )}
    </div>
  );
}
