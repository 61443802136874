const dev = {
    url: {
        URL_PATH: 'http://localhost:3000/',
        API_PATH: 'http://localhost:3000/API/',
    }
}
const prod = {
    url: {
        URL_PATH: '/',
        API_PATH: '/API/',
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;