import { Alert, Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { config } from "./../constants";
import "./login.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import { Helmet } from "react-helmet";

const firebaseConfig = {
  apiKey: "AIzaSyDw7FpBkkoqI2RkazuaDAH87RmyF7BIPyw",
  authDomain: "whiskey-club-37f90.firebaseapp.com",
  projectId: "whiskey-club-37f90",
  storageBucket: "whiskey-club-37f90.appspot.com",
  messagingSenderId: "325157876448",
  appId: "1:325157876448:web:40e24e8da05ce613689685",
};
// Initialize Firebase
const app: any = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export default function Login({ onLogin = (data: any) => {} }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);

  const submitForm = async () => {
    console.log(username);
    console.log(password);

    try {
      const response = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      console.log(response);
      onLogin(response);
    } catch (err: any) {
      if (err.code === "auth/wrong-password") {
        setOpen(true);
      } else {
        setWarningOpen(true);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | The Anorak Group</title>
        <link rel="canonical" href="http://theanorakgroup.org/" />
        <link rel="shortlink" href="http://theanorakgroup.org/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Login - The Anorak Group" />
        <meta property="og:url" content="http://theanorakgroup.org/" />
        <meta
          property="og:site_name"
          content="The Anorak Group"
          data-react-helmet="true"
        />
        <meta
          name="title"
          content="The Anorak Group"
          data-react-helmet="true"
        />
        <meta name="description" content="The Anorak Group" />
      </Helmet>
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box component="span" m={1}>
            <div className="form-holder">
              <div className="logo-holder">
                <img src={config.url.URL_PATH + `images/logo.svg`} />
              </div>
              <div className="elements">
                <h1>Login</h1>

                <TextField
                  required
                  id="username-input"
                  label="email"
                  onChange={(evt: any) => {
                    setUsername(evt.target.value);
                  }}
                />

                <TextField
                  required
                  id="password-input"
                  label="Password"
                  type="password"
                  onChange={(evt: any) => {
                    setPassword(evt.target.value);
                  }}
                />

                <Button variant="contained" onClick={submitForm}>
                  LOGIN
                </Button>
              </div>
            </div>
          </Box>
        </Grid>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">Wrong Credentials. Please Try Again.</Alert>
      </Snackbar>
      <Snackbar
        open={warningOpen}
        autoHideDuration={6000}
        onClose={() => setWarningOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="warning">
          There was a problem logging in. Please check your form and try again.
        </Alert>
      </Snackbar>
    </div>
  );
}
