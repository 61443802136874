import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import "./page.scss";
import { doc, orderBy, setDoc } from "firebase/firestore";
import { Helmet } from "react-helmet";


import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { CircularProgress } from "@mui/material";

const firebaseConfig = {
  apiKey: "AIzaSyDw7FpBkkoqI2RkazuaDAH87RmyF7BIPyw",
  authDomain: "whiskey-club-37f90.firebaseapp.com",
  projectId: "whiskey-club-37f90",
  storageBucket: "whiskey-club-37f90.appspot.com",
  messagingSenderId: "325157876448",
  appId: "1:325157876448:web:40e24e8da05ce613689685",
};

// Initialize Firebase
const app: any = initializeApp(firebaseConfig);
const db = getFirestore(app);
let datalist: any = [];

export default function Bylaws() {
  const [page, setPage] = useState([]);

  const getPage = async () => {
    datalist = [];
    const q = query(collection(db, "pages"), where("title", "==", "By-Laws and FAQ"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const docdata = doc.data();
      datalist.push(
        <div className="page-item" key={Math.random()}>
          <h1 dangerouslySetInnerHTML={{__html:docdata.title}}></h1>
          <div className="page-content"><div dangerouslySetInnerHTML={{__html:docdata.content}}></div></div>
        </div>
      );
    });
    setPage(datalist); 
  };

  useEffect(() => {
    getPage();
  }, []);

  return (
    <div>
         <Helmet>
        <meta charSet="utf-8" />
        <title>By-Laws and FAQ | The Anorak Group</title>
        <link rel="canonical" href="http://theanorakgroup.org/" />
        <link rel="shortlink" href="http://theanorakgroup.org/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="By-Laws and FAQ - The Anorak Group" />
        <meta property="og:url" content="http://theanorakgroup.org/" />
        <meta
          property="og:site_name"
          content="The Anorak Group"
          data-react-helmet="true"
        />
        <meta
          name="title"
          content="The Anorak Group"
          data-react-helmet="true"
        />
        <meta name="description" content="The Anorak Group" />
      </Helmet>
      <Header />
      <div className="page-container">
        {page}
      </div>
      <Footer />
    </div>
  );
}
