import React from "react";
import Footer from "../footer";
import "./error404.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './draft.scss';
import { Helmet } from "react-helmet";


export default function Draft() {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Draft | The Anorak Group</title>
          <link rel="canonical" href="http://theanorakgroup.org/" />
          <link rel="shortlink" href="http://theanorakgroup.org/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Draft - The Anorak Group" />
          <meta property="og:url" content="http://theanorakgroup.org/" />
          <meta property="og:site_name" content="The Anorak Group" data-react-helmet="true"/>
          <meta name="title" content="The Anorak Group" data-react-helmet="true" />
          <meta
            name="description"
            content="The Anorak Group"
          />
        </Helmet>
      <div className="editor">
      <Editor
        // editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        // onEditorStateChange={this.onEditorStateChange}
      />
      </div>
      <Footer />
    </>
  );
}

