import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import "./calendar.scss";
import { CircularProgress } from "@mui/material";
import { collection, doc, getDocs, getFirestore, orderBy, query, setDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Helmet } from "react-helmet";


const firebaseConfig = {
  apiKey: "AIzaSyDw7FpBkkoqI2RkazuaDAH87RmyF7BIPyw",
  authDomain: "whiskey-club-37f90.firebaseapp.com",
  projectId: "whiskey-club-37f90",
  storageBucket: "whiskey-club-37f90.appspot.com",
  messagingSenderId: "325157876448",
  appId: "1:325157876448:web:40e24e8da05ce613689685",
};
// Initialize Firebase
const app: any = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export default function Calendar() {
  const [loading, setLoading] = useState(true);
  const [calendarData, setCalendarData] = useState([]);
  let datalist:any = [];

  const getCalendarData = async () => {
    datalist = [];
    const q = query(collection(db, "events"), orderBy("Date", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      const docdata = doc.data();
      let newsDate:any = new Date(docdata.Date.seconds*1000).toLocaleString().split(",")[0];
      newsDate = newsDate.split("/");
      let month = newsDate[0];
      let day = newsDate[1];
      if(month.length === 1) {month = "0" + month; }
      if(day.length === 1) {day = "0" + day; }
      const formattedDate = newsDate[2] + "-" + month + "-" + day;
      console.log(formattedDate);
      datalist.push(
        {title: docdata.title, date: formattedDate}
      );
    });
    setCalendarData(datalist);
    setLoading(false);
  };

  useEffect(()=> {
    getCalendarData();
  }, []);

  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Calendar | The Anorak Group</title>
          <link rel="canonical" href="http://theanorakgroup.org/" />
          <link rel="shortlink" href="http://theanorakgroup.org/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Calendar - The Anorak Group" />
          <meta property="og:url" content="http://theanorakgroup.org/" />
          <meta property="og:site_name" content="The Anorak Group" data-react-helmet="true"/>
          <meta name="title" content="The Anorak Group" data-react-helmet="true" />
          <meta
            name="description"
            content="The Anorak Group"
          />
        </Helmet>
      <Header />
      <div className="calendar-holder">
        {loading ? (
          <div className="loader"><CircularProgress /></div>
        ) : (
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={calendarData}
          />
        )}
      </div>
      <Footer />
    </>
  );
}
