import { Button } from "@mui/material";
import React from "react";
import "./header.scss";
import { getAuth, signOut } from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import { config } from "./constants";
declare var location: any;

export default function Header() {
  const navigate = useNavigate();

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // do nothing....
      })
      .catch((error) => {
        // do nothing...
      });
  };
  return (
    <div className="header">
      <div>
        <img src={`${config.url.URL_PATH}images/logo-wide.svg`} />
      </div>
      <div>
        <Button
          variant="outlined"
          startIcon={<PeopleIcon />}
          onClick={() => navigate("/members")}
        >
          Members
        </Button>
        <Button
          variant="outlined"
          startIcon={<ArticleIcon />}
          onClick={() => navigate("/by-laws")}
        >
          By-Laws and FAQ
        </Button>
        <Button
          variant="outlined"
          startIcon={<PersonIcon />}
          onClick={() => logout()}
        >
          SIgn Out
        </Button>
      </div>
    </div>
  );
}
