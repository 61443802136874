import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import { doc, orderBy, setDoc } from "firebase/firestore";
import './home.scss';
import { Helmet } from "react-helmet";

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { CircularProgress } from "@mui/material";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDw7FpBkkoqI2RkazuaDAH87RmyF7BIPyw",
  authDomain: "whiskey-club-37f90.firebaseapp.com",
  projectId: "whiskey-club-37f90",
  storageBucket: "whiskey-club-37f90.appspot.com",
  messagingSenderId: "325157876448",
  appId: "1:325157876448:web:40e24e8da05ce613689685",
};
// Initialize Firebase
const app: any = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
let datalist:any = [];

export default function Home() {
  const [newsList, setNewsList] = useState([]);
  
  const getNews = async () => {
    datalist = [];
    const q = query(collection(db, "news"), orderBy("Date", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      const docdata = doc.data();
      let newsDate = new Date(docdata.Date.seconds*1000).toLocaleString().split(",")[0];
      console.log(newsDate);
      datalist.push(
        <div className="news-item" key={Math.random()}>
          <h2>{docdata.title}</h2>
           <div className="meta">
            {newsDate}
          </div>
          <div className="news-item-desc">
            <div dangerouslySetInnerHTML={{__html:docdata.description}}></div>
          </div>
        </div>
      );
    });
    setNewsList(datalist);
    // console.log(datalist);
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
     <Helmet>
          <meta charSet="utf-8" />
          <title>Home | The Anorak Group</title>
          <link rel="canonical" href="http://theanorakgroup.org/" />
          <link rel="shortlink" href="http://theanorakgroup.org/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home page - The Anorak Group" />
          <meta property="og:url" content="http://theanorakgroup.org/" />
          <meta property="og:site_name" content="The Anorak Group" data-react-helmet="true"/>
          <meta name="title" content="The Anorak Group" data-react-helmet="true" />
          <meta
            name="description"
            content="The Anorak Group"
          />
        </Helmet>
      <Header />
      <div className="news-list">
        <h1>Latest Club News</h1>
        {newsList}
      </div>
      <Footer />
    </>
  );
}
