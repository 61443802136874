import { Link, useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import NewsIcon from "@mui/icons-material/Newspaper";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import PourIcon from "@mui/icons-material/Liquor";
import { useEffect, useState } from "react";
import './footer.scss';

export default function Footer() {
  const [navValue, setNavValue] = useState(-1);
  const route: any = useLocation();
  const navigate = useNavigate();
  const routes = ["/", "/calendar", "/previous-pours"];

  useEffect(() => {
    console.log("loading...");
    switch (route.pathname) {
      case "/":
        setNavValue(0);
        break;
      case "/calendar":
        setNavValue(1);
        break;
      case "/previous-pours":
        setNavValue(2);
        break;
    }
  }, []);

  return (
    <div className="footer">
      <BottomNavigation
        showLabels
        value={navValue}
        onChange={(event, newValue) => {
          setNavValue(newValue);
          navigate(routes[newValue]);
          console.log(newValue);
        }}
      >
        <BottomNavigationAction label="News" icon={<NewsIcon />} />
        <BottomNavigationAction label="Calendar" icon={<CalendarIcon />} />
        <BottomNavigationAction label="Previous Pours" icon={<PourIcon />} />
      </BottomNavigation>
    </div>
  );
}
