import React from "react";
import Footer from "../footer";
import "./error404.scss";
import { Helmet } from "react-helmet";


export default function Error404() {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>404 | The Anorak Group</title>
          <link rel="canonical" href="http://theanorakgroup.org/" />
          <link rel="shortlink" href="http://theanorakgroup.org/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="404 - The Anorak Group" />
          <meta property="og:url" content="http://theanorakgroup.org/" />
          <meta property="og:site_name" content="The Anorak Group" data-react-helmet="true"/>
          <meta name="title" content="The Anorak Group" data-react-helmet="true" />
          <meta
            name="description"
            content="The Anorak Group"
          />
        </Helmet>
      <div className="error-screen">
        <h1>Error, Page Not Found!</h1>
      </div>
      <Footer />
    </>
  );
}
