import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./previousPours.scss";
import { Helmet } from "react-helmet";
import { CircularProgress } from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDw7FpBkkoqI2RkazuaDAH87RmyF7BIPyw",
  authDomain: "whiskey-club-37f90.firebaseapp.com",
  projectId: "whiskey-club-37f90",
  storageBucket: "whiskey-club-37f90.appspot.com",
  messagingSenderId: "325157876448",
  appId: "1:325157876448:web:40e24e8da05ce613689685",
};
// Initialize Firebase
const app: any = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const createData = (name, country, proof, type, rating, date) => {
  return { name, country, proof, type, rating, date };
};
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 3),
  createData("Eclair", 262, 16.0, 24, 6.0, 3),
  createData("Cupcake", 305, 3.7, 67, 4.3, 3),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 3),
];

export default function PreviousPours() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  let datalist: any = [];

  const getTableData = async () => {
    datalist = [];
    const q = query(collection(db, "previousPours"), orderBy("date", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const docdata: any = doc.data();
      let newsDate: any = new Date(docdata.date.seconds * 1000).toLocaleString().split(",")[0];
      newsDate = newsDate.split("/");
      let month = newsDate[0];
      let day = newsDate[1];
      if (month.length === 1) {
        month = "0" + month;
      }
      if (day.length === 1) {
        day = "0" + day;
      }
      const formattedDate = newsDate[2] + "-" + month + "-" + day;
      datalist.push({
        name: docdata.name,
        country: docdata.country,
        proof: docdata.proof,
        type: docdata.type,
        rating: docdata.rating,
        date: formattedDate,
      });
    });
    setTableData(datalist);
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Previous Pours | The Anorak Group</title>
          <link rel="canonical" href="http://theanorakgroup.org/" />
          <link rel="shortlink" href="http://theanorakgroup.org/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Previous Pours - The Anorak Group" />
          <meta property="og:url" content="http://theanorakgroup.org/" />
          <meta property="og:site_name" content="The Anorak Group" data-react-helmet="true"/>
          <meta name="title" content="The Anorak Group" data-react-helmet="true" />
          <meta
            name="description"
            content="The Anorak Group"
          />
        </Helmet>
      <Header />
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="pours-table">
          <h1>Previous Pours</h1>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Whiskey Name</TableCell>
                  <TableCell align="right">Country</TableCell>
                  <TableCell align="right">Proof</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Rating (0-5)</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.country}</TableCell>
                    <TableCell align="right">{row.proof}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.rating}</TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <Footer />
    </>
  );
}
